import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "./http";

export const financeRkapApi = createApi({
    reducerPath: "financeRkapApi",
    tagTypes: ["finance-rkap"],
    baseQuery: axiosFetchBase("/finance-rkap"),
    endpoints: (builder) => ({
        lockRkap: builder.mutation({
            query: (data) => ({
                url: "/lock",
                method: "POST",
                body: {
                    action: data.action,
                    type: data.type,
                    date: data.date,
                },
            }),
            invalidatesTags: ["finance-rkap"],
        }),
    })
});

export const {
    useLockRkapMutation,
} = financeRkapApi;

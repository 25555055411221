import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const financeExpenseApi = createApi({
    reducerPath: "financeExpenseApi",
    tagTypes: ["finance-expenses"],
    baseQuery: axiosFetchBase("/finance-expenses"),
    endpoints: (builder) => ({
        getFinanceExpenses: builder.query({
            query: (data) => ({
                url: "/",
                method: "GET",
                query: {
                    filter: data.filter,
                    search: data.search,
                    page: data.page,
                    pageSize: data.pageSize,
                    startDate: data.startDate,
                    endDate: data.endDate,
                },
            }),
            providesTags: [
                {
                    type: "finance-expenses",
                    id: "list",
                },
            ],
        }),
        getFinanceExpenseById: builder.query({
            query: (data) => ({
                url: `/${data.id}`,
                method: "GET",
                initial: data.initial,
            }),
            providesTags: (_, __, arg) => [
                { type: "finance-expenses", id: arg.id },
            ],
        }),
        getFinanceExpenseReceiver: builder.query({
            query: (data) => ({
                url: "/receiver",
                method: "GET",
                query: {
                    search: data.search,
                    type: data.type,
                },
            }),
            providesTags: (_, __, arg) => [
                { type: "finance-expense-receivers", id: arg.type },
            ],
        }),
        authorizeFinanceExpense: builder.mutation({
            query: (data) => ({
                url: `/authorize/${data.expenseId}`,
                method: "POST",
            }),
            invalidatesTags: ["finance-expenses"],
        }),
        payFinanceExpense: builder.mutation({
            query: (data) => ({
                url: `/pay/${data.expenseId}`,
                method: "POST",
                body: {
                    id_account: data.accountId,
                    tgl_exec: data.date,
                    media: data.media,
                }
            }),
            invalidatesTags: ["finance-expenses", "finance-journals"],
        }),
        addFinanceExpense: builder.mutation({
            query: (data) => ({
                url: "/",
                method: "POST",
                body: {
                    tgl_fintransaction: data.tgl_fintransaction,
                    id_receiver: data.id_receiver,
                    table_receiver: data.table_receiver,
                },
            }),
            invalidatesTags: ["finance-expenses"],
        }),
        addFinanceExpenseDetail: builder.mutation({
            query: (data) => ({
                url: "/detail",
                method: "POST",
                body: {
                    id_fintransaction: data.expenseId,
                    id_account: data.accountId,
                    value_fintransactiondetail: data.value,
                    name_form: data.nameForm,
                    comt_fintransactiondetail: data.comment,
                    media: data.media,
                },
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-expenses", id: "list" },
                { type: "finance-expenses", id: arg.expenseId },
            ],
        }),
        updateFinanceExpense: builder.mutation({
            query: (data) => ({
                url: `/${data.expenseId}`,
                method: "PUT",
                body: {
                    tgl_fintransaction: data.tgl_fintransaction,
                    id_receiver: data.id_receiver,
                    table_receiver: data.table_receiver,
                    media: data.media,
                    removed_media: data.removedMedia,
                },
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-expenses", id: "list" },
                { type: "finance-expenses", id: arg.expenseId },
            ],
        }),
        updateFinanceExpenseDetail: builder.mutation({
            query: (data) => ({
                url: `/detail/${data.id}`,
                method: "PUT",
                body: {
                    id_account: data.accountId,
                    value_fintransactiondetail: data.value,
                    name_form: data.nameForm,
                    comt_fintransactiondetail: data.comment,
                    media: data.media,
                },
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-expenses", id: "list" },
                { type: "finance-expenses", id: arg.expenseId },
            ],
        }),
        deleteFinanceExpense: builder.mutation({
            query: (data) => ({
                url: `/${data.expenseId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-expenses", id: "list" },
                { type: "finance-expenses", id: arg.expenseId },
            ],
        }),
        deleteFinanceExpenseDetail: builder.mutation({
            query: (data) => ({
                url: `/detail/${data.expenseId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-expenses", id: "list" },
                { type: "finance-expenses", id: arg.expenseId },
            ],
        }),
        checkIsAllowedToAuthorizeFinanceExpense: builder.query({
            query: (data) => ({
                url: "/check/authorize",
                method: "GET",
                query: {
                    amount: data.amount,
                },
            }),
            providesTags: (_, __, arg) => [
                { type: "finance-expense-autho-status", amount: arg.amount },
            ],
        }),
        checkIsAllowedToPayFinanceExpense: builder.query({
            query: (data) => ({
                url: "/check/pay",
                method: "GET",
                query: {
                    amount: data.amount,
                },
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-expense-pay-status", amount: arg.amount },
            ],
        }),
    }),
});

export const {
    useGetFinanceExpensesQuery,
    useGetFinanceExpenseByIdQuery,
    useGetFinanceExpenseReceiverQuery,
    useAddFinanceExpenseDetailMutation,
    useAddFinanceExpenseMutation,
    usePayFinanceExpenseMutation,
    useAuthorizeFinanceExpenseMutation,
    useUpdateFinanceExpenseMutation,
    useUpdateFinanceExpenseDetailMutation,
    useDeleteFinanceExpenseMutation,
    useDeleteFinanceExpenseDetailMutation,
    useCheckIsAllowedToAuthorizeFinanceExpenseQuery,
    useCheckIsAllowedToPayFinanceExpenseQuery,
} = financeExpenseApi;

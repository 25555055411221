import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const financeLedgerApi = createApi({
    reducerPath: "financeLedgerApi",
    tagTypes: ["finance-ledgers"],
    baseQuery: axiosFetchBase("/finance-ledgers"),
    endpoints: (builder) => ({
        getFinanceLedgers: builder.query({
            query: (args) => ({
                url: "/",
                method: "GET",
                query: {
                    start: args.start,
                    end: args.end,
                    account: args.account,
                },
            }),
            providesTags: [{ type: "finance-ledgers", id: "list" }],
        }),
    }),
});

export const { useLazyGetFinanceLedgersQuery } = financeLedgerApi;
